:root {
  --white: #ffffff;
  --black: #080810;
  --primary: #bb9669;
  --primaryHover: rgb(187 150 105 / 0.75);
  --accent: #857fd4;
  --accentHover: #857fd4bf;
  --accent-hsl: 244, 50%, 66%;
  --warn: #ffce70;
  --warn-hsl: 39, 100%, 72%;
  --error: #f11212;
  --danger: #ee6c64;
  --success: #4fc9a0;
  --success-hsl: 160, 53%, 55%;

  --secondary: #e6e8ec;
  --tertiary: #8f93a0;
  --text-main: #fbfbfc;
  --color: var(--text-main);

  --light: #f8f8fa;

  --card-border: #3e3e3e;
  --card-bg: #1e1e1e;

  --dialog-bg: #1a1a1a;

  --counter-bg: #131313;

  --filters-bg: #535353;

  --skeleton-bg: #1e1e1e;
    
  --divider-gradients: #3e3e3e;
  --ion-text-color: var(--color);
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-dark: var(--black) !important;

  --background-color: var(--black);
  --ion-background-color: var(--background-color);
}
