ion-footer {
  --footer-toolbar-vertical-padding: 20px;
  --border-width: 0px;
  ion-toolbar {
    --padding-top: var(--footer-toolbar-vertical-padding) !important;
    --background: linear-gradient(109deg, rgba(255, 255, 255, 0.24) -4.09%, rgba(255, 254, 254, 0.06) 100.72%);

    &:not(.ios) {
      //IOS HAVE SAFE AREA
      --padding-bottom: var(--footer-toolbar-vertical-padding) !important;
    }
  }
}
