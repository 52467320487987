.custom-bottomsheet {
  align-items: flex-end;
  --background: transparent;
  --height: auto;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  transition: all 1s ease;

  &.full-height > .ion-page {
    height: calc(var(--vh, 1vh) * 100 - var(--ion-safe-area-top)) !important;
  }

  .ion-page {
    max-height: calc(var(--vh, 1vh) * 100 - var(--ion-safe-area-top)) !important;
    background: var(--dialog-bg);
    border-radius: 20px 20px 0 0 !important;
    position: relative;
    display: block;
    contain: content;

    ion-toolbar {
      --background: var(--dialog-bg);
    }

    main {
      padding: 0 24px 24px 24px;
      height: 100%;
      overflow: auto;
      overflow-y: overlay;

      padding-top: 4px;
    }
  }
}
