.success-chip {
  @apply rounded-2xl text-success bg-success-hsl/10 caption-3 px-3 py-1;
}

.accent-chip {
  @apply rounded-2xl text-accent bg-accent-hsl/10 caption-3 px-3 py-1;
}

.warn-chip {
  @apply rounded-2xl text-warn bg-warn-hsl/10 caption-3 px-3 py-1;
}
