.custom-mat-accent-select {
  --mat-option-selected-state-label-text-color: var(--accent) !important;
  background-color: var(--black) !important;
  border: 1px solid var(--accent);
  border-radius: 8px !important;
  margin-top: 0.5rem;

  .mat-pseudo-checkbox {
    display: none;
  }
}
