.otp-input {
  height: 49px !important;
  width: 49px !important;
  background-color: transparent;
  border-radius: 8px !important;

  &:focus-visible {
    outline: auto !important;
    outline-color: var(--accent) !important;
  }
}
