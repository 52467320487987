ion-modal {
  ion-content,
  .content {
    --background: var(--dialog-bg);
    background: var(--dialog-bg);
  }

  ion-header ion-toolbar:first-of-type {
    padding-top: 0;
  }
}
