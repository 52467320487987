:root {
  --swiper-pagination-color: var(--primary);
}

//* Swiper
swiper-container {
  width: 100%;
}

@keyframes bounce {
  0% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  55% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.swiper-slide-active:not(.first-slide):not(.last-slide) {
  -webkit-animation: bounce 400ms both;
  animation: bounce 400ms both;
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.swiper-pagination-bullet {
  display: inline-block;
  width: 15px;
  height: 6px;
  background: var(--secondary);
  border-radius: 8px;
  transition: all 0.3s;

  &-active {
    width: 61px;
    height: 6px;
    background: var(--swiper-pagination-color);
  }
}

.swiper-button-prev {
  width: 53px;
  height: 53px;
}

.swiper-button-next {
  width: 53px;
  height: 53px;
}

.swiper-button-disabled {
  color: var(--card-border);
}