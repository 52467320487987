mat-selection-list {
  mat-list-option {
    --mdc-checkbox-state-layer-size: 22px;
    border-bottom: 1px solid var(--secondary);

    .mdc-list-item__start {
      margin-right: 12px !important;
    }

    .mdc-list-item__primary-text {
      font-size: 13px !important;
      color: var(--secondary) !important;
    }

    .mdc-checkbox__background {
      border-radius: 6px !important;

      .mdc-checkbox__checkmark {
        width: 10px;
        height: 14px;
        margin: 0 auto;
        display: inline-table;
        vertical-align: middle;
      }
    }
  }
}
