//BUTTONS
button {
  --font-size: 14px;
  --mdc-protected-button-label-text-size: var(--font-size);
  --mdc-protected-button-label-text-color: var(--color) !important;
  --mdc-icon-button-state-layer-size: var(--height) !important;
  --mdc-protected-button-container-height: var(--height) !important;
  --mdc-outlined-button-container-height: var(--height) !important;
  display: flex !important;
  justify-content: center;

  .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:disabled {
    opacity: 0.48;

    &.mat-primary {
      --mdc-protected-button-disabled-container-color: var(--primary);
    }

    &.mat-accent {
      --mdc-protected-button-disabled-container-color: var(--accent);
    }

    &.mat-warn {
      --mdc-protected-button-disabled-container-color: var(--warn);
    }
  }

  //SIZES
  &.xxs {
    --font-size: 12px;
    line-height: 12px;
  }

  &.xs {
    --height: 40px;
  }

  &.small {
    --height: 48px;
  }

  &.medium {
    --height: 56px;
  }

  //PRIMARY BUTTON
  &.mat-mdc-raised-button {
    --mdc-protected-button-container-shape: var(--standard-radius);
    --mdc-protected-button-container-color: var(--primary);
  }

  //SECONDARY BUTTTON
  &.mat-mdc-outlined-button {
    --mdc-outlined-button-container-shape: var(--standard-radius);
    --mdc-outlined-button-outline-color: var(--primary);
  }
}
