app-card {
  border: 1px solid var(--card-border);
  border-radius: var(--standard-radius);
  background-color: transparent;
  position: relative;
  display: block;

  &.active {
    background-color: var(--card-bg);
  }
}
