ion-header {
  border-color: transparent;
  border-width: 0;
  box-shadow: none !important;

  --header-toolbar-vertical-padding: 12px;
  ion-toolbar {
    --padding-top: var(--header-toolbar-vertical-padding) !important;
    --padding-bottom: var(--header-toolbar-vertical-padding) !important;
  }

  &.no-safe-padding-top {
    ion-toolbar {
      padding-top: 0 !important;
    }
  }
}
