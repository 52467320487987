:root {
  --default-input-height: 52px;
  --ion-font-family: 'MavenPro';
  --ion-margin: 16px;
  --ion-padding: 24px;
  --standard-radius: 8px;
  --content-padding: 24px;
  --tab-container-height: 83px;
}

@import './components/mat-buttons';
@import './components/mat-form-fields';
@import './components/mat-selection-list';
@import './components/mat-tabs';
@import './components/snackbar';
@import './components/card';
@import './components/swiper';
@import './components/custom-bottomsheet';
@import './components/custom-toast';
@import './components/ion-header';
@import './components/ion-footer';
@import './components/ion-toolbar';
@import './components/ion-content';
@import './components/ion-modal';
@import './components/ion-tab-bar';
@import './components/otp-input';
@import './components/counter';
@import './components/sorting-button';
@import './components/search-bar';
@import './components/mat-chip';
@import './components//chip-variants';
@import './components/mat-select';
@import './components/mat-chip-listbox';
@import './components/mat-badge-content';
@import './components/custom-blur-modal';
@import './components/mat-menu';
@import './components/chatlio';
