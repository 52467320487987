mat-snack-bar-container {
  margin-top: calc(var(--ion-safe-area-top) + 12px) !important;
  --mdc-snackbar-container-color: transparent;
  .mat-mdc-snackbar-surface {
    padding: 0 !important;
  }

  .mdc-snackbar__label {
    padding: 0 !important;
  }
}
