app-search-bar {
  mat-form-field {
    --mdc-outlined-text-field-outline-color: var(--card-border);

    .search-icon {
      color: var(--tertiary);
    }

    &.mat-focused,
    &.filled {
      .search-icon {
        color: var(--text-main);
      }
    }
  }
}
