.custom-blur-modal {
  --background: rgba(83, 83, 83, 0.7);
  --height: 100vh;
  backdrop-filter: blur(4px);

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    ion-toolbar {
      --background: transparent;
    }
  }
}
