mat-chip-listbox {
  .mdc-evolution-chip-set__chips {
    padding: 4px 24px 0 0;
    flex-wrap: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none; //? Hide scrollbar for Chrome, Safari and Opera
    }

    //? Hide scrollbar for IE, Edge and Firefox
    scrollbar-width: none;
  }
}
