app-custom-toast {
  .toast {
    &.success-toast {
      background: linear-gradient(92deg, #0f231d 0%, #4fc9a0 100%);
    }
    &.error-toast {
      background: linear-gradient(92deg, #642d2a 0%, #ee6c64 100%);
    }
    &.warning-toast {
      background: linear-gradient(92deg, #4c3d2b 0%, #bb9669 100%);
    }
  }
}
