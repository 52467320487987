@import '../node_modules/@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '../node_modules/@ionic/angular/css/structure.css';

/* Optional CSS utils that can be commented out */
@import '../node_modules/@ionic/angular/css/padding.css';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/css/flag-icon.min.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  .input-row {
    gap: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .success-message,
  .error-message {
    font-size: 14px;
    color: var(--white);
    padding: 8px 12px;
    border-radius: var(--standard-radius);
    margin-bottom: 12px;
  }

  .error-message {
    background: var(--error);
  }

  .subtitle {
    @apply text-tertiary text-xs mt-3 mb-5;
  }

  .scrollable-list {
    padding-bottom: calc(var(--tab-container-height) + 40px);
    padding-right: 24px;
    padding-left: 24px;
    overflow: auto;
  }

  .border-primary-gradient {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1px;
      border-radius: inherit;
      background: linear-gradient(to bottom right, #bb9669, #1c1c1b);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
}
