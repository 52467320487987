ion-content {
  position: relative;
  --content-padding: 24px;
  --padding-start: var(--content-padding);
  --padding-end: var(--content-padding);
  --padding-bottom: var(--content-padding);

  &.padding-top-safe {
    --padding-top: env(safe-area-inset-top);
  }

  &.padding-bottom-with-tab {
    --padding-bottom: calc(var(--tab-container-height) + 40px);
  }
}
