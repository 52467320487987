$drvn-primary: (
  50: #f7f2ed,
  100: #ebe0d2,
  200: #ddcbb4,
  300: #cfb696,
  400: #c5a680,
  500: #bb9669,
  600: #b58e61,
  700: #ac8356,
  800: #a4794c,
  900: #96683b,
  A100: #fff2e5,
  A200: #ffd7b3,
  A400: #ffbd80,
  A700: #ffaf66,
  contrast: (
    50: #ffffff,
    100: #151516,
    200: #151516,
    300: #151516,
    400: #151516,
    500: #151516,
    600: #151516,
    700: #151516,
    800: #151516,
    900: #ffffff,
    A100: #151516,
    A200: #151516,
    A400: #151516,
    A700: #151516,
  ),
);

$drvn-accent: (
  50: #eae9f8,
  100: #cac7ed,
  200: #a7a3e0,
  300: #7771ae,
  400: #6d62ca,
  500: #857fd4,
  600: #523cb2,
  700: #4a31a5,
  800: #432699,
  900: #370f82,
  A100: #9b95ff,
  A200: #6b62ff,
  A400: #3c2fff,
  A700: #2415ff,
  contrast: (
    50: #151516,
    100: #151516,
    200: #151516,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #151516,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$drvn-warn: (
  50: #fde8e8,
  100: #fbc7c7,
  200: #f9a1a1,
  300: #f67b7b,
  400: #f45f5f,
  500: #f24343,
  600: #f03d3d,
  700: #ee3434,
  800: #ec2c2c,
  900: #e81e1e,
  A100: #ffffff,
  A200: #ffebeb,
  A400: #ffb8b8,
  A700: #ff9e9e,
  contrast: (
    50: #151516,
    100: #151516,
    200: #151516,
    300: #151516,
    400: #151516,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #151516,
    A200: #151516,
    A400: #151516,
    A700: #151516,
  ),
);

$drvn-danger: (
  50: #fde8e8,
  100: #fbc7c7,
  200: #f9a1a1,
  300: #f67b7b,
  400: #f45f5f,
  500: #f24343,
  600: #f03d3d,
  700: #ee3434,
  800: #ec2c2c,
  900: #e81e1e,
  A100: #ffffff,
  A200: #ffebeb,
  A400: #ffb8b8,
  A700: #ff9e9e,
  contrast: (
    50: #151516,
    100: #151516,
    200: #151516,
    300: #151516,
    400: #151516,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #151516,
    A200: #151516,
    A400: #151516,
    A700: #151516,
  ),
);

@use '@angular/material' as mat;
@include mat.core();

/* ======== Angular material custom themes ======== */
$app-primary: mat.m2-define-palette($drvn-primary, 500);
$app-accent: mat.m2-define-palette($drvn-accent, 500);
$app-warn: mat.m2-define-palette($drvn-warn, 500);

$brand-light-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);
@include mat.all-component-themes($brand-light-theme);

///FONT

@font-face {
  font-family: 'MavenPro';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/maven/MavenPro-Medium.ttf');
}

@font-face {
  font-family: 'MavenPro';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/maven/MavenPro-Bold.ttf');
}

@font-face {
  font-family: 'MavenPro';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/maven/MavenPro-Regular.ttf');
}

$my-custom-typography: mat.m2-define-typography-config(
  $font-family: 'MavenPro',
  $headline-5: mat.m2-define-typography-level(40px, 48px, 500),
  /*h1*/ $headline-6: mat.m2-define-typography-level(32px, 40px, 500),
  /*h2*/ $subtitle-1: mat.m2-define-typography-level(16px, 22px, 400),
  /*h3*/ $body-1: mat.m2-define-typography-level(16px, 24px, 400),
  /*h4*/ $body-2: mat.m2-define-typography-level(16px, 24px, 400),
  $button: mat.m2-define-typography-level(14px, 16px, 500),
);

@include mat.all-component-typographies($my-custom-typography);

@media (max-width: 768px) {
  $mobile-typography: mat.m2-define-typography-config(
    $font-family: 'MavenPro',
    $headline-5: mat.m2-define-typography-level(20px, 1, 500),
    /*h1*/ $headline-6: mat.m2-define-typography-level(18px, 24px, 500),
    /*h2*/ $subtitle-1: mat.m2-define-typography-level(14px, 20px, 400),
    /*h3*/ $body-1: mat.m2-define-typography-level(14px, 20px, 400),
    /*h4*/ $body-2: mat.m2-define-typography-level(14px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 16px, 500),
  );
  @include mat.all-component-typographies($mobile-typography);
}
