//* Sorting Button
app-sorting-button {
  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 36px;
  }
  .mdc-icon-button {
    width: 36px;
    height: 36px;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    border-radius: 8px;
    border: 1px solid var(--card-border);
    padding: 5px !important;

    &.is-active {
      border: 1px solid var(--accent);
    }
  }

  .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
    border-radius: 8px;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    width: 36px;
    height: 36px;
  }
}
