mat-form-field {
  width: 100%;
  min-width: 0px;
  padding-right: 1px;
  --mat-form-field-container-vertical-padding: 14px;
  --mat-form-field-container-height: 52px;
  --input-radius: var(--standard-radius);
  --mdc-outlined-text-field-outline-color: var(--tertiary);
  --mdc-outlined-text-field-hover-outline-color: var(--tertiary);
  --mdc-outlined-text-field-container-shape: var(--standard-radius);
  --mdc-outlined-text-field-focus-outline-color: var(--accent);
  --mdc-outlined-text-field-focus-label-text-color: var(--accent);
  --mdc-outlined-text-field-label-text-color: var(--tertiary);
  --mdc-outlined-text-field-input-text-color: var(--color);
  --mat-select-focused-arrow-color: var(--accent);
  --mat-option-selected-state-label-text-color: var(--accent);
  --mdc-shape-small: var(--input-radius);
  --placeholder-color: var(--tertiary);
  --hint-size: 33px;

  .mat-mdc-form-field-subscript-wrapper:not(.mat-mdc-form-field-subscript-dynamic-size) {
    height: var(--hint-size);
  }

  &.mat-mdc-form-field-has-icon-prefix {
    --mat-mdc-form-field-label-offset-x: 30px;
    --mat-mdc-form-field-label-transform: translateY(-50%) translateX(calc(1 * (0px + var(--mat-mdc-form-field-label-offset-x, 0px))));
  }

  &.disable-with-enabled-appearance {
    --mdc-outlined-text-field-disabled-outline-color: var(--mdc-outlined-text-field-outline-color);
    --mdc-outlined-text-field-disabled-label-text-color: var(--mdc-outlined-text-field-label-text-color);
    --mdc-outlined-text-field-disabled-input-text-color: var(--mdc-outlined-text-field-input-text-color);
    --mat-select-disabled-trigger-text-color: var(--mdc-outlined-text-field-input-text-color);
  }

  &.filled.ng-valid {
    --mdc-outlined-text-field-outline-color: var(--white);
    --mdc-outlined-text-field-hover-outline-color: var(--white);
  }

  .mat-mdc-form-field-icon-prefix > *,
  .mat-mdc-form-field-icon-suffix > * {
    padding: 0 12px;
    box-sizing: content-box;
  }

  .mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
  }

  .mdc-notched-outline__notch {
    border-right: none;
  }

  ::placeholder {
    color: var(--placeholder-color) !important;
    opacity: 1;
  }

  &.s {
    --mat-form-field-container-vertical-padding: 8px;
    --mat-form-field-container-height: 40px;
  }

  &.m {
    --mat-form-field-container-vertical-padding: 12px;
    --mat-form-field-container-height: 48px;
  }

  &.l {
     --mat-form-field-container-vertical-padding: 18px
     --mat-form-field-container-height: 56px;
  }

  textarea {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
