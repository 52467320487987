.mat-mdc-standard-chip {
  --mdc-chip-selected-label-text-color: var(--white) !important;
  --mdc-chip-elevated-container-color: var(--card-bg);
  --mdc-chip-label-text-color: var(--tertiary);
  --mdc-chip-label-text-color: var(--tertiary);
  @apply transition-all duration-300;
}

.mat-mdc-chip-action-label {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
